import React from 'react';
import { connect } from 'react-redux';

import API_ENDPOINTS from '../../services/apiConfig';
import RestClient from '../../services/RestClient';
import AuthManager from '../../services/AuthManager';
import careHost from '../../utils/careHost';

import PayuBlackLogo from '@assets/images/PayU-Icon.svg';
import { Step1, Step2 } from '../../components/NeedAssessment';
import LoadingPage from '../../components/BaseComponents/AppLoader';
import { pushGTMEvent } from '@services/gtm';

class NeedAssessment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      loading: true,
      answers: []
    };
    
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this._handleSelectStep1 = this._handleSelectStep1.bind(this);
    this._handleSelectStep2 = this._handleSelectStep2.bind(this);
    this._captureAnswers = this._captureAnswers.bind(this);
  }

  componentWillMount() {
    document.body.style.background = 'none';
  }

  componentDidMount() {
    this.getQuestions();
  }

  async getQuestions() {
    try {
      const response = await RestClient.get(API_ENDPOINTS.NEED_ASSESSMENT.GET);
      this.setState({
        loading: false,
        currentStep: 1,
        questions: response.assessment_questions
      });
    } catch(err) {
      window.location.href = '/app/welcome';
    }
  }

  async _captureAnswers() {
    const user = AuthManager.getUser() || {};
    const { merchant_account_uuid } = user || {};
    const { assessment_question_id, selected_options } = this.state.answers;
    const isCustomer = (
      this.state.answers
      && this.state.answers[0]
      && this.state.answers[0].category == 'Customer'
    )
    AuthManager.persistUserToLocalStorage({ ...user, new_product_account: false });
    try {
      pushGTMEvent({
        eventType: 'formec',
        payload: { enhanced_conversion_data: { email: user?.email } },
      })
      await RestClient.post(
        API_ENDPOINTS.NEED_ASSESSMENT.POST(merchant_account_uuid),
        {
          assessment_responses: this.state.answers.map((ans) => ({
            assessment_question_id: ans.assessment_question_id,
            selected_options: ans.selected_options
          }))
        }
      );
    } finally {
      window.location.href = isCustomer ? process.env.CARE_HOST : '/app/welcome';
    }
  }
  
  _next() {
    let currentStep = this.state.currentStep;
    // Make sure currentStep is set to something reasonable
    if (currentStep >= 1) {
      currentStep = 2;
    } else {
      currentStep = currentStep + 1;
    }
    
    this.setState({
      currentStep: currentStep
    });
  }
   
  _prev() {
    let currentStep = this.state.currentStep;
    if (currentStep <= 1) {
      currentStep = 1;
    } else {
      currentStep = currentStep - 1;
    }
    
    this.setState({
      currentStep: currentStep
    });
  }

  _handleSelectStep1(id, category) {
    const { id: assessment_question_id } = this.state.questions[0];
    this.setState({
      answers: [{
        assessment_question_id,
        selected_options: [id],
        category,
      }, {
        assessment_question_id: this.state.questions[1].id,
        selected_options: []
      }]
    })
  }

  _handleSelectStep2(e, inputId) {
    e.preventDefault();
    let step_answer = this.state.answers[1];
    
    if (step_answer.selected_options.includes(inputId)) {
      step_answer.selected_options = step_answer.selected_options.filter((id) => id!==inputId);
    } else {
      step_answer.selected_options = step_answer.selected_options.concat(inputId)
    }
    
    this.setState({
      answers: [
        this.state.answers[0],
        step_answer
      ]
    })
  }

  renderContent(){
    return (
      <div className="frst-ntf-waves">
        <div className="main-content">
          <div className="container">
            <div className="logo-main"> <PayuBlackLogo /></div>
          </div>
          <div className="notification first custom-welcome p-0 fonts-wrap grey-background grid-bg">
          {
            this.state.loading ? <LoadingPage /> : (
              <div className="need-assement-bg grey-background">
                <Step1
                  currentStep={this.state.currentStep}
                  questions={this.state.questions}
                  answers={this.state.answers}
                  next={this._next}
                  previous={this._prev}
                  handleSelect={this._handleSelectStep1}
                />
                <Step2
                  currentStep={this.state.currentStep}
                  questions={this.state.questions}
                  answers={this.state.answers}
                  previous={this._prev}
                  handleSelect={this._handleSelectStep2}
                  onConfirm={this._captureAnswers}
                />
              </div>
            )
          }
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}

export default connect()(NeedAssessment);
