import { config, RedirectionService as Redirection } from 'hub-redux-auth';
import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from "redux-saga";

import reducer from '../src/reducers';
import cleverTap from '../src/services/cleverTap';
import AccountCreated from '../src/components/AccountCreated';
import { checkUserForProducts, getRedirectUrl, hasNowMerchantCameToMoney } from '../src/services/utils';
import { fetchUserMerchants } from '../src/actions/auth';
import { onRedirectionCLick } from '../src/services/utils';
import careHost from '../src/utils/careHost';
import { isProfileDetailsFilled, isWebsiteDetailsFilled } from '../src/reducers/selectors';
import { sendEvergageEvent } from '../onboarding/services/Evergage';

/* eslint-disable no-underscore-dangle */
let initialState = {};

function createReduxStore(preloadedState, sagaMiddleware) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  initialState = preloadedState;
  return window.__redux_store_dump = createStore(
    // preloadedState to pass intitial state in reducers
    reducer, preloadedState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );
}

const
  clientId = process.env.HUB_CLIENT_ID,
  hubHost = process.env.HUB_HOST,
  marketingBoxHost = process.env.MARKETING_BOX_HOST,
  bizRedirectionUrl = process.env.BIZ_LOGIN_URL,
  productLoginUrl = process.env.PRODUCT_LOGIN_URL,
  productHost = process.env.PRODUCT_HOST,
  oneDashboardUrl = process.env.ONE_DASHBOARD_HOST,
  vistaDashboardUrl = process.env.VISTA_DASHBOARD_HOST;

config.set('grantType', 'client_credentials');
config.set('clientId', clientId);
config.set('productRedirectUrl', productLoginUrl);
config.set('tokenScope', 'find_or_create send_sign_in_otp verify_sign_in_otp');
config.set('oneDashboardUrl', oneDashboardUrl);
config.set('vistaDashboardUrl', vistaDashboardUrl);

config.set('loginRedirectUrl', (user, newLogin, url = productLoginUrl) => {
  if (user && url) {
    const loginConfig = user.attached_configs && user.attached_configs.find(config => config.name === 'login_config')
    const loginConfigCheck = loginConfig && loginConfig.config && loginConfig.active &&
      loginConfig.config.redirection_url === 'onboarding_dashboard' &&
      [null, 'Thirdparty Hold'].includes(user.settlement_status);
    // TO BE REFACTORED
    const redirectionConfig = user.attached_configs && user.attached_configs.find(config => config.name === 'advance_settlement')
    if (redirectionConfig && redirectionConfig?.config?.dashboard_redirection_url) {
      window.location = redirectionConfig?.config?.dashboard_redirection_url;
      return;
    }
    const isAuthConnectFlow = window.localStorage.getItem('reseller_id');
    if (newLogin && hasNowMerchantCameToMoney(user.business_origin, user.product, initialState.product)) {
      window.location = '/app/terms-conditions';
    } else if (newLogin && user.new_merchant && user.is_cs_eligible && user.cs_plan) {
      sendEvergageEvent({ actionType: 'signup', payload: { user: { id: user?.mid } } })
      window.location = '/app/plans';
    } else if ((loginConfigCheck && url === productLoginUrl) || isAuthConnectFlow) {
        window.location = '/app/onboarding'
    } else if (newLogin && user.merchant_type === 'aggregator' && user.settlement_status !== 'Active') {
      window.location = '/app/onboarding';
    } else if (newLogin && user.new_product_account) {
      sendEvergageEvent({ actionType: 'signup', payload: { user: { id: user?.mid } } })
      if (Redirection.redirectToParam) {
        onRedirectionCLick(Redirection.redirectToParam)
      } else {
        window.location = '/app/assessment'
      }
    } else if (user.mid) {
      sendEvergageEvent({ actionType: 'login', payload: { user: { id: user?.mid } } })
      if (user.product === 'PayUbiz') {
        /**
         * if PayUbiz merchant comes from care panel then redirect the merchant to care panel
         * no need to pass authToken, mid and product in queryParams
         * these parameters are set in cookies
         */
        if (url.includes(careHost())) {
          window.location.href = url;
        } else if (Redirection.redirectToParam) {
          onRedirectionCLick(Redirection.redirectToParam)
        } else if (user.dashboard_preference === 'one_dashboard') {
          onRedirectionCLick(url);
        } else if (url.includes(bizRedirectionUrl) && (url.includes('redirectTo') || url.includes('redirect_to'))) {
          /**
           * pass redirectToUrl in post params to biz redirection only if bizRedirectionUrl is present
           * with query params in redirectionUrl
           */
          onRedirectionCLick(url);
        } else {
          onRedirectionCLick();
        }
      } else if (user.product === 'PayUmoney' || user.product === 'PayUnow') {
        window.location.assign(getRedirectUrl(user, url, user.new_merchant && !!newLogin));
      }
    } else {
      window.location.assign('/app/account/team-accounts');
    }
  } else {
    return window.location.pathname.includes('/app') ? '/onboarding' : '/app/onboarding';
  }
});
config.set('fetchUserMerchants', fetchUserMerchants);
config.set('productCheck', checkUserForProducts);
config.set('createUserUrl', '/api/v3/product_enrollments/find_or_create');
config.set('hubHost', hubHost);
config.set('emailOnlyOtpVerificationURL', '/api/v3/product_enrollments/verify_and_move_mid')
config.set('marketingBoxHost', marketingBoxHost);
config.set('cleverTap', cleverTap);
config.set('walletUrl', 'http://upiapp.citruspay.com');
config.set('accountCreatedComponent', AccountCreated);
config.set('productHost', productHost); //used in redirection of merchant to 'Contact Us'  page
/* eslint-enable */
config.set('disableOnboarding', process.env.DISABLE_ONBOARDING == 'true');
config.set('isTestModeEnabled', process.env.IS_TEST_MODE_ENABLED == 'true');
export default createReduxStore;
