import AuthManager from '../src/services/AuthManager';
import { fetchParams } from './service_redirect';
import { extractDomainFromString } from '@utils/common';
import { config } from 'hub-redux-auth';

export async function logoutUser(callback) {
  try {
    const productInfo = document.getElementById('product_information'),
      { logoutUrl,  } = productInfo?.dataset || {},
      bizLogoutUrl = process.env.BIZ_HOST + process.env.BIZ_LOGOUT_PATH,
      moneyLogoutUrl = process.env.MONEY_HOST + process.env.MONEY_LOGOUT_PATH,
      oneDashboardLogoutUrl = process.env.PAYU_WEBSITE_HOST,
      user = AuthManager.getUser();

    const allowedRedirectionUrls = process.env.ALLOWED_REDIRECTION_URLS;
    const paramsObj = fetchParams(window.location.search);
    const redirectTo = paramsObj["redirect_to"] || paramsObj["redirectTo"];
    const redirectHostUrl = redirectTo ? (
      allowedRedirectionUrls.split(' ').includes(extractDomainFromString(redirectTo)) ? extractDomainFromString(redirectTo) : null
    ) : null;
    let oneDashboardUrl = config.get('oneDashboardUrl');
    let vistaDashboardUrl = config.get('vistaDashboardUrl');
    oneDashboardUrl = new URL(oneDashboardUrl);
    vistaDashboardUrl = new URL(vistaDashboardUrl);
    /* when token info is present in localStorage */
    /* when user info is not present then redirectHostUrl is used to redirect to respective service */
    if (window.localStorage.getItem('tokenData')) {
      /**
       * if logoutUrl is present give priority to it as it will redirect to the logout path
       */
      if (user.dashboard_preference === 'one_dashboard'){
        if (redirectTo && redirectTo.includes(vistaDashboardUrl.hostname)) {
          AuthManager.logout(redirectTo);
        } else {
          AuthManager.logout(oneDashboardLogoutUrl);
        }
      } else if (logoutUrl){
        AuthManager.logout(logoutUrl);
      } else if (user && user.product === 'PayUbiz'){
        AuthManager.logout(bizLogoutUrl);
      } else if (user && user.product === 'PayUmoney'){
        AuthManager.logout(moneyLogoutUrl);
      } else {
        /**
         * this case to handle when authToken and user is present in localStorage
         * but not logged in to the respective service
         */
        AuthManager.logout();
      }
    } else if (logoutUrl && logoutUrl.includes(redirectHostUrl)) {
      window.location.href = logoutUrl;
    } else if (bizLogoutUrl && bizLogoutUrl.includes(redirectHostUrl)){
      window.location.href = bizLogoutUrl;
    } else if (moneyLogoutUrl && moneyLogoutUrl.includes(redirectHostUrl)){
      window.location.href = moneyLogoutUrl;
    } else {
      /**
       * when authToken and user is not present is localStorage
       * merchant clicks on log_out then merchant should be redirected to '/app'
       */
      window.location.href = '/app';
    }
  } catch (err) {
    callback('code-break');
    console.log(err);
  }
}

// logoutUser();
