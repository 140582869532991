/*
 *
 * CleverTap events name
 *
 */

export const LOGIN_SUCCESSFULL = 'LOGIN_SUCCESSFULL';
export const LOGIN_WITH_PASSWORD_INITIATED = 'LOGIN_WITH_PASSWORD_INITIATED';
export const LOGIN_WITH_MOTP_INITIATED = 'LOGIN_WITH_MOTP_INITIATED';
export const LOGIN_WITH_EOTP_INITIATED = 'LOGIN_WITH_EOTP_INITIATED';
export const MERCHANT_FILTERED_BY_STATUS = 'MERCHANT_FILTERED_BY_STATUS';
export const OTP_VERIFIACTION_ERROR = 'OTP_VERIFIACTION_ERROR';
export const OTP_VERIFIACTION = 'OTP_VERIFIACTION';
export const LOGIN_FLOW_INITIATED = 'LOGIN_FLOW_INITIATED'
export const EMAIL_FILLED = 'EMAIL_FILLED';
export const CREATE_ACCOUNT_NEXT_CTA_ERROR = 'CREATE_ACCOUNT_NEXT_CTA_ERROR';
export const CREATE_ACCOUNT_INITIATED = 'CREATE_ACCOUNT_INITIATED';
export const CREATE_ACCOUNT_SUCCESSFULL = 'CREATE_ACCOUNT_SUCCESSFULL';
export const LOGOUT = 'LOGOUT';
export const fieldValueChange = fieldName => `${fieldName} filled`;
export const FILTER_BY = 'FILTER_BY_STATUS_OR_PRODUCT_OR_SETTLEMENT'

export const CLEVERTAP_EVENTS_MAP = {
  LOGIN_SUCCESSFULL: "Logged in successfully",
  BUSINESS_DETAILS_SUBMITTED: "Business details submitted",
  BANK_DETAILS_SUBMITTED: "Bank details submitted",
  BANK_DETAILS_UPDATED: "Bank details updated",
  DOCUMENT_UPLOADED: "Document uploaded",
  TOOL_SELECTED: "Need assessment submitted",
  PHONE_NUMBER_UPDATED: "Phone number updated",
  PASSWORD_UPDATED: "Password updated",
  ADDRESS_UPDATED: "Address updated",
  WEBSITE_UPDATED: "Website updated",
  SERVICE_AGREEMENT_ACCEPTED: "Service agreement accepted",
  DOCUMENTS_SUBMITTED_SUCCESSFUL: "Documents submitted successfully",
  CREATE_ACCOUNT_SUCCESSFULL: "Sign up platform"
}

export const CLEVERTAP_EVENTS = {
  Bank_ACC_NO_FILLED: "Bank_ACC_NO_FILLED",
  IFSC_CODE_VERIFIED: "IFSC_code_verified",
  DOCS_SUBMITTED: "DOCS_SUBMITTED",
  DOCS_FAILED: "DOCS_FAILED",
  DOCS_REJECTED: "DOCS_REJECTED",
  PAN_ENTERED: "PAN_ENTERED",
  NAME_ENTERED: "NAME_ENTERED",
  NAME_PREFILLED: "NAME_PREFILLED",
  DOB_PREFILLED: "DOB_PREFILLED",
  DOB_ENTERED: "DOB_ENTERED",
  ENTITY_TYPE_SELECTED : "ENTITY_TYPE_SELECTED",
  CATEGORY_SUBCATEGORY_FILLED:"CATEGORY_SUBCATEGORY_FILLED",
  TOOLS_MERCHANT: "Tools merchant",
  WEBSITE_MERCHANT: "WEBSITE_MERCHANT",
  URL_ENTERED: "URL_ENTERED",
  AS_EMAIL_FILLED: "AS_EMAIL_FILLED",
  AS_PHONE_FILLED: "AS_PHONE_FILLED",
  AS_NAME_FILLED: "AS_NAME_FILLED",
  PAN_VERIFIED : "PAN verified",
  PAN_FAILED : "PAN Failed",
  PAN_STEP_COMPLETED : "PAN STEP COMPLETED",
  GST_NO_GIVEN : "GST_No given",
  ANNUAL_TURNOVER: "Annual Turnover",
  GST_STEP_COMPLETED: "GST STEP Completed",
  CIN_VERIFIED : "CIN_VERIFIED",
  Pep_CONSENT_Y : "Pep_Consent Y",
  Pep_CONSENT_N : "Pep_Consent N",
  Pep_Consent_RELATED : "Pep_Consent related",
  UBO_Y: "UBO_Y",
  UBO_N: "UBO_N",
  UBO_DETAILS_NAME: "UBO_details- Name",
  UBO_DETAILS_DOB: "UBO_details- DOB",
  UBO_DETAILS_PAN: "UBO_details- PAN",
  UBO_DETAILS_ADDRESSLINE: "UBO_details- AddressLine",
  UBO_DETAILS_PINCODE: "UBO_details- Pincode",
  UBO_DETAILS_UPLOAD: "UBO_details- Upload",
  UBO_DETAILS_OWNERSHIP_ENTERED: "UBO_details- Ownership_entered",
  AS_UBO_SUBMITTED: "AS_UBO_SUBMITTED",
  Docs_REUPLOADED : "Docs_REUPLOADED",
  Docs_APPROVED : "Docs_APPROVED",
  DOCS_UPLOADED : "DOCS_UPLOADED",
  VIDEO_KYC_STARTED : "VIDEO_KYC_STARTED",
  VIDEO_KYC_SCHEDULED : "VIDEO_KYC_SCHEDULED",
  AGREEMENT_SIGNED : "AGREEMENT_SIGNED",
  AGREEMENT_GENERATED : "AGREEMENT_GENERATED"
}